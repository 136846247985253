import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import {
  getMainDomain,
  getTld,
} from "@mittwald/flow-lib/dist/validation/domain";
import { assert } from "@sindresorhus/is";
import invariant from "invariant";
import { validate } from "jsonschema";
import { FieldValues } from "react-hook-form";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import { HeavyListRecords } from "../../components/Form/HeavyListFieldArrayContainer";
import ApiDataError from "../../errors/ApiDataError";
import { resolveJsonSchemaConditionalValues } from "../../lib/jsonSchema";
import { ArticleAttributeKey, ArticleTemplateName } from "../article";
import ArticleFactory from "../article/ArticleFactory";
import { EmailAddressList } from "../mail/EmailAddressList";
import ListModel from "../misc/ListModel";
import UserInputList from "../misc/userInput/UserInputList";
import UserInputRecordList, {
  UserInputsRecordObject,
} from "../misc/userInput/UserInputRecordList";
import Order from "../order/Order";
import { AnyProject, Project } from "../project";
import {
  DomainTypes,
  DomainUI,
  NameServerType,
  TargetTypes,
} from "../ui/domain/DomainUI";
import DomainContacts from "./DomainHandles";
import { DomainList } from "./DomainList";
import DomainName from "./DomainName";
import { GeneratedDomain } from "./GeneratedDomains";
import Ingress from "./Ingress";
import { IngressList } from "./IngressList";
import { ProcessList } from "./process/ProcessList";

export interface HandleInputs {
  adminIsOwner: boolean;
  ownerC: UserInputsRecordObject;
  adminC: UserInputsRecordObject;
}

export type HandleData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_CreateDomainHandleData;

export type AuthCode =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_AuthCode;

export type DomainApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_Domain;

export type PreviewOrderData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_DomainOrderPreviewResponse;

export type DomainOrderPreviewResponse =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_DomainOrderPreviewResponse;

export interface DomainTargetInputs extends FieldValues {
  installationId: string;
  url: string;
  directory: string;
  container: string;
  containerPort: string;
}

export interface DomainPathInputs extends DomainTargetInputs {
  path: string;
  targetType: TargetTypes;
}

export interface BookDomainInputs extends HandleInputs, DomainTargetInputs {
  domainType: DomainTypes.bookDomain;
  otherAdmin: boolean;
  targetType: TargetTypes;
  type: string;
  domainCheckerUsed: string;
  selectedDomain: string;
  generatedDomains: GeneratedDomain[];
  generatorPrompt: string;
}

export interface MoveDomainInputs extends HandleInputs, DomainTargetInputs {
  domainType: DomainTypes.moveDomain;
  otherAdmin: boolean;
  authCode: string;
  targetType: TargetTypes;
  type: string;
}

export interface VHostInputs extends DomainTargetInputs {
  domainType: DomainTypes.vHost;
  hostname: string;
  targetType: TargetTypes;
  type: string;
}
export interface SubdomainInputs extends DomainTargetInputs {
  domainType: DomainTypes.subdomain;
  subdomain: string;
  hostname: string;
  targetType: TargetTypes;
  type: string;
}

export type NewDomainInputs =
  | VHostInputs
  | SubdomainInputs
  | BookDomainInputs
  | MoveDomainInputs;

export interface ContactUpdateInput {
  adminIsOwner: boolean;
  ownerC: UserInputsRecordObject;
  adminC: UserInputsRecordObject;
  confirmOwnership: boolean;
}

export interface UpdateDomainInputs extends DomainTargetInputs {
  targetType: TargetTypes;
  nameServerType: NameServerType | "inherited";
  nameServer: HeavyListRecords;
}

export interface DomainOrder {
  data: {
    hostname?: string;
  };
}

export const defaultNameserver = [
  "ns01.agenturserver.de",
  "ns01.agenturserver.it",
  "ns01.agenturserver.co",
];

export class Domain {
  public readonly data: DomainApiData;
  public readonly id: string;
  public readonly contacts: DomainContacts;
  public readonly ready: boolean;
  public readonly processes: ProcessList;
  public readonly domain: string;
  public readonly isInternalDomainWithInternalNameServers: boolean;
  public readonly nameservers: string[];

  private constructor(data: DomainApiData) {
    this.data = Object.freeze(data);
    this.id = data.domainId;
    this.processes = new ProcessList(data.processes ?? []);
    this.domain = data.domain;
    this.ready =
      this.processes.isEmpty || !this.processes.isAuthCodeMismatchError();
    this.contacts = DomainContacts.fromApiData(data);
    this.isInternalDomainWithInternalNameServers = data.usesDefaultNameserver;
    this.nameservers = data.nameservers;
  }

  public static fromApiData = (data: DomainApiData): Domain => {
    return new Domain(data);
  };

  public static useLoadById(id: string): Domain {
    const data = mittwaldApi.domainGetDomain
      .getResource({ path: { domainId: id } })
      .useWatchData();
    return Domain.fromApiData(data);
  }

  public static useTryLoadById(id: string): Domain | undefined {
    const data = mittwaldApi.domainGetDomain
      .getResource({ path: { domainId: id } })
      .useWatchData({ optional: true });
    return data ? Domain.fromApiData(data) : undefined;
  }

  public useProject(): AnyProject {
    return Project.useLoadById(this.data.projectId);
  }

  public static usePreviewOrder(
    domain: string,
    projectId: string,
  ): DomainOrderPreviewResponse {
    const data = mittwaldApi.orderPreviewOrder
      .getResource({
        requestBody: {
          orderType: "domain",
          orderData: {
            domain,
            projectId,
          },
        },
      })
      .useWatchData();

    invariant("domainPrice" in data, "Expected domain order preview");

    return data;
  }

  public static useTryLoadByHostname(
    hostname?: string,
    projectId?: string,
  ): Domain | undefined {
    const domains = DomainList.useTryLoadAllByProjectId(projectId).useItems();

    return domains.find((d) => d.data.domain === hostname);
  }

  public useLoadIngressesOfDomain(): Ingress[] {
    const ingresses = IngressList.useLoadAllByProjectId(
      this.data.projectId,
    ).useItems();
    return ingresses.filter((ingress) => {
      const ingressParse = DomainName.parse(ingress.hostname);
      const domainParse = DomainName.parse(this.domain);

      return ingressParse.domain === domainParse.domain;
    });
  }

  public static useLoadByHostname(hostname: string, projectId: string): Domain {
    const domain = this.useTryLoadByHostname(hostname, projectId);
    if (!domain) {
      throw new Error("domain not found");
    }
    return domain;
  }

  public async updateContacts(input: ContactUpdateInput): Promise<void> {
    const { ownerC } = input;

    const response = await mittwaldApi.domainUpdateDomainContact.request({
      path: { domainId: this.id, contact: "owner" },
      requestBody: {
        contact: UserInputRecordList.toObjectList(ownerC),
      },
    });

    assertStatus(response, 200);
  }

  public async createAuthCode(): Promise<string> {
    const response = await mittwaldApi.domainCreateDomainAuthCode.request({
      path: { domainId: this.id },
    });

    assertStatus(response, 201);

    return response.content.authCode;
  }

  public async moveDomainAuthInfoCorrection(authCode: string): Promise<void> {
    const response = await mittwaldApi.domainUpdateDomainAuthCode.request({
      path: { domainId: this.id },
      requestBody: {
        authCode,
      },
    });

    assertStatus(response, 200);
  }
  public async moveDomainAbort(): Promise<void> {
    const response = await mittwaldApi.domainAbortDomainDeclaration.request({
      path: { domainId: this.id },
    });

    assertStatus(response, 204);
  }

  public async updateNameServer(values: UpdateDomainInputs): Promise<boolean> {
    const response = await mittwaldApi.domainUpdateDomainNameservers.request({
      path: { domainId: this.id },
      requestBody: {
        nameservers: values.nameServer.map((v) => v.value),
      },
    });
    if (
      response.status === 400 &&
      response.content.message?.includes("nameservers are not valid")
    ) {
      return false;
    }

    assertStatus(response, 204);
    return true;
  }

  public async restoreDefaultNameServer(): Promise<void> {
    const response = await mittwaldApi.domainUpdateDomainNameservers.request({
      path: { domainId: this.id },
      requestBody: {
        nameservers: defaultNameserver,
      },
    });

    assertStatus(response, 204);
  }

  public static async previewOrder(
    domain: string,
    projectId: string,
  ): Promise<PreviewOrderData> {
    const response = await mittwaldApi.orderPreviewOrder.request({
      requestBody: {
        orderType: "domain",
        orderData: {
          domain,
          projectId,
        },
      },
    });

    assertStatus(response, 200);

    return response.content as PreviewOrderData;
  }

  public static useIsAvailable(
    domain: string,
  ): "available" | "unavailable" | "isPremium" | undefined {
    const data = mittwaldApi.domainCheckDomainRegistrability
      .getResource({
        requestBody: { domain: getMainDomain(domain) ?? "" },
      })
      .useWatchData({
        optional: true,
        throwOnError: false,
      });

    if (!data) {
      return;
    }

    if (data.isPremium) {
      return "isPremium";
    }

    return data.registrable ? "available" : "unavailable";
  }

  public static async isAvailable(
    domain: string,
  ): Promise<
    | "available"
    | "domainNotAvailable"
    | "domainIsPremium"
    | "failedToCheck"
    | "domainHasInvalidCharacters"
  > {
    const response = await mittwaldApi.domainCheckDomainRegistrability.request({
      requestBody: { domain: getMainDomain(domain) ?? "" },
    });
    if (response.status === 400) {
      return "domainHasInvalidCharacters";
    }

    if (response.status === 200) {
      if (response.content.isPremium) {
        return "domainIsPremium";
      }
      return response.content.registrable ? "available" : "domainNotAvailable";
    }

    return "failedToCheck";
  }

  public static async order(
    values: BookDomainInputs | MoveDomainInputs,
    projectId: string,
    domain: string,
  ): Promise<void> {
    const response = await mittwaldApi.orderCreateOrder.request({
      requestBody: {
        orderType: "domain",
        orderData: {
          authCode:
            values.domainType === DomainTypes.moveDomain
              ? values.authCode
              : undefined,
          domain: DomainUI.normalizeDashes(domain),
          projectId,
          handleData: {
            adminC: values.adminIsOwner
              ? undefined
              : UserInputRecordList.toObjectList(values.adminC),
            ownerC: UserInputRecordList.toObjectList(values.ownerC),
          },
        },
      },
    });

    assertStatus(response, 201);
  }

  public useArticleName(): string | undefined {
    const contract = mittwaldApi.contractGetDetailOfContractByDomain
      .getResource({
        path: {
          domainId: this.id,
        },
      })
      .useWatchData();

    return contract.additionalItems?.find(
      (i) => i.aggregateReference?.id === this.id,
    )?.articles[0]?.name;
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.domainDeleteDomain.request({
      path: { domainId: this.id },
    });

    assertStatus(response, 200);
  }

  public static usePendingDomains(project: AnyProject): ListModel<Domain> {
    const existingDomains = DomainList.useLoadAllByProjectId(
      project.id,
    ).useItems();

    return new ListModel(
      existingDomains.filter(
        (d) => !d.processes.isEmpty && d.processes.containsDeclareRequested(),
      ),
    );
  }

  public static usePendingOrders(project: AnyProject): ListModel<DomainOrder> {
    const existingDomains = DomainList.useLoadAllByProjectId(
      project.id,
    ).useItems();
    const existingSDomainHostnames = existingDomains.map((s) => s.data.domain);

    const domainArticles = ArticleFactory.useLoadAllByTemplate(
      ArticleTemplateName.domain,
    );

    const allOrders = Order.useLoadAllForProject(project, {
      includesStatus: ["CONFIRMED"],
    });

    return new ListModel(
      allOrders
        .filter((o) => o.data.type === "NEW_ORDER")
        .flatMap((o) => o.items)
        .filter((i) => domainArticles.some((a) => i.referencesArticle(a)))
        .map((i) => ({
          data: {
            hostname: i.getAttributeValue(ArticleAttributeKey.domain),
          },
        }))
        .filter(
          (i) =>
            i.data.hostname === undefined ||
            !existingSDomainHostnames.includes(i.data.hostname),
        ),
    );
  }

  public useEmailAddressesExist(): boolean {
    const emailAddresses = EmailAddressList.useLoadAllByProjectId(
      this.data.projectId,
    ).useItems();

    return emailAddresses.some(
      (address) => address.getDomainPart() === this.data.domain,
    );
  }

  public static useHandleUserInputs(
    domainName: string,
    currentHandleData?: HandleInputs,
  ):
    | {
        adminC?: UserInputList;
        ownerC: UserInputList;
      }
    | undefined {
    const tld = getTld(domainName);
    if (!tld) {
      throw new Error("missing tld");
    }

    const handleSchemas = mittwaldApi.domainListTldContactSchemas
      .getResource({ path: { tld: tld } })
      .useWatchData();

    try {
      let adminCSchema = undefined;
      if (handleSchemas.jsonSchemaAdminC) {
        validate({}, handleSchemas.jsonSchemaAdminC);
        adminCSchema = resolveJsonSchemaConditionalValues(
          handleSchemas.jsonSchemaAdminC,
          currentHandleData?.adminC,
        );
      }

      validate({}, handleSchemas.jsonSchemaOwnerC);
      const ownerCSchema = resolveJsonSchemaConditionalValues(
        handleSchemas.jsonSchemaOwnerC,
        currentHandleData?.ownerC,
      );

      return {
        adminC: adminCSchema
          ? UserInputList.fromHandleSchema(adminCSchema)
          : undefined,
        ownerC: UserInputList.fromHandleSchema(ownerCSchema),
      };
    } catch (error) {
      assert.error(error);
      throw new ApiDataError(`Schema is invalid: ${error.message}`);
    }
  }
}

export default Domain;
