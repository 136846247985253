import $RefParser from "@apidevtools/json-schema-ref-parser";
import _dereference from "@apidevtools/json-schema-ref-parser/dist/lib/dereference";
import addFormats from "ajv-formats";
import Ajv, { SchemaObject as Schema } from "ajv/dist/2019";
import { validate as validatePartialSchema } from "jsonschema";
import { ValidateResult } from "react-hook-form";
import * as R from "remeda";

export const ajv = new Ajv({
  allErrors: true,
});
addFormats(ajv);

export type ResolveSchemaReturn<T> = T extends Schema
  ? Record<string, { name: string; schema: Schema; required: boolean }>
  : undefined;

const dereferenceJsonSchema = (schema: Record<string, any>): Schema => {
  const parser = new $RefParser();
  void parser.parse(schema);
  parser.schema = schema;
  _dereference(parser, {
    mutateInputSchema: false,
  });
  return schema;
};

export function resolveJsonSchemaConditionalValues<
  T extends Schema | undefined,
>(
  schema: T,
  conditionalValues: Record<string, any> = {},
): ResolveSchemaReturn<T> {
  if (!schema) {
    return undefined as ResolveSchemaReturn<T>;
  }

  let conditionalProps = [];
  if (schema.if) {
    conditionalProps.push({
      if: schema.if,
      then: schema.then,
      else: schema.else,
    });
  }
  if (schema.allOf) {
    conditionalProps = [...conditionalProps, ...schema.allOf];
  }

  R.pipe(
    conditionalProps,
    R.map((x) => {
      const conditionPass = R.allPass(
        x.if?.properties || x.if.not?.properties,
        R.pipe(
          (x.if?.properties || x.if?.not?.properties) ?? {},
          R.mapValues((value, key) => (): boolean => {
            if (
              x.required &&
              R.isArray(x.required) &&
              x.required.includes(key)
            ) {
              return x.if?.not
                ? conditionalValues[key] !== value.const
                : conditionalValues[key] === value.const;
            }

            return x.if?.not
              ? (conditionalValues[key] ?? "") !== value.const
              : (conditionalValues[key] ?? "") === value.const;
          }),
          R.values,
        ),
      );

      return { ...(conditionPass ? x.then : x.else) };
    }),
    R.forEach((itemSchema) => {
      const mergedSchema = R.mergeDeep(
        {
          ...schema,
        },
        itemSchema,
      ) as Schema;

      schema = R.mergeDeep(mergedSchema, {
        required: R.unique([
          ...(schema && R.isArray(schema.required) ? schema.required : []),
          ...(R.isArray(itemSchema.required) ? itemSchema.required : []),
        ]),
      }) as T;
    }),
  );

  const deRefSchema = dereferenceJsonSchema(schema);

  const allProperties = deRefSchema.properties ?? {};
  const requiredFields = R.isArray(deRefSchema.required)
    ? deRefSchema.required
    : [];

  return R.pipe(
    allProperties,
    R.mapValues((item: any, key) => ({ ...item, name: key })),
    R.values,
    R.sortBy([
      (item) => {
        const requiredIndex = requiredFields.findIndex(
          (rf) => rf === item.name,
        );

        if (requiredIndex === -1) {
          return 0;
        }

        return requiredIndex;
      },
      "asc",
    ]),
    R.mapToObj((v) => {
      return [
        v.name,
        {
          name: v.name,
          schema: allProperties[v.name],
          required: requiredFields.includes(v.name),
        },
      ];
    }),
  ) as ResolveSchemaReturn<T>;
}

export const validateJsonSchema = (
  value: any,
  schema: Schema,
): ValidateResult => {
  const validationResult = validatePartialSchema(value, schema, {
    throwError: false,
  });

  if (validationResult.valid) {
    return true;
  }

  const errorElement = validationResult.errors[0];
  const errorTitle = errorElement?.argument?.id?.slice(1, -1);

  if (
    errorElement &&
    typeof errorElement.schema !== "string" &&
    errorTitle &&
    errorTitle.includes("subschema")
  ) {
    const schemaNumber = errorTitle.match(/(\d+)/);
    if (!schemaNumber) {
      return false;
    }

    const validationSchema = (errorElement.schema as Record<string, any>)[
      errorElement.name
    ][parseInt(schemaNumber[0])];

    if ("title" in validationSchema) {
      return validationSchema.title;
    }

    return false;
  }

  return errorTitle ?? false;
};
